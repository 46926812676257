// 报表
import request from '@/utils/request2'

// 获取移动端报表中心列表
export function getReportList(params) {
  const { username } = params
  return request(
    {
      url: '/api/wx/getReportList',
      method: 'get',
      params: { username }
    },
    false
  )
}

// 18.	获取预览报表参数窗体明细数据接口
export function getPreViewParamList(params) {
  const { rpid } = params
  return request({
    url: '/api/report/getPreViewParamList',
    method: 'get',
    params: { rpid }
  })
}

// 19.	预览点确定后调用的接口
export function saveReportInputParamList(data) {
  const {
    guid,
    rpid,
    moduleno,
    mtablename,
    mviewname,
    mkeyname,
    formfieldlist,
    curdataset,
    isall,
    autoidlist,
    moduleqyfilter,
    specfilter,
    querysql,
    username,
    paramvaluelist,
    optionfilter
  } = data
  return request({
    url: '/api/report/saveReportInputParamList',
    method: 'post',
    data: {
      guid,
      rpid,
      moduleno,
      mtablename,
      mviewname,
      mkeyname,
      formfieldlist,
      curdataset,
      isall,
      autoidlist,
      moduleqyfilter,
      specfilter,
      querysql,
      username,
      paramvaluelist,
      optionfilter
    }
  })
}
